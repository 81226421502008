<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover" v-loading="loading.table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Invoice Number</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Ref. Number</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in rows" :key="i">
                    <td>{{ item.date_str }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.invoice_number }}</td>
                    <td>{{ item.topup_amount_str }}</td>
                    <td>
                      <el-tag size="mini" :type="item.tag_type">{{ item.status.replace('_', ' ').toUpperCase() }}</el-tag>
                    </td>
                    <td>{{ item.reference_number || '-' }}</td>
                    <td>
                      <!-- <a :href="item.topup_receipt_url" class="text-normal" target="_blank" v-if="item.status == 'paid' || item.status == 'waiting_payment'">
                        <font-awesome icon="file-pdf"/>&nbsp;{{ item.status == 'waiting_payment' ? 'Proforma Invoice' : 'Invoice' }}
                      </a>
                      <span v-else>-</span> -->
                      <el-button @click="handlerBtnPay(item)" v-if="item.status == 'waiting_payment'" size="mini">Pay</el-button>
                      <el-button type="primary" @click="handlerDownloadInvoice(item)" v-if="item.status == 'paid'" size="mini">Download Invoice</el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="!rows.length" description="No histories"></el-empty>
            </div>
            <b-pagination v-if="total_row > search.per_page" v-model="currentPage" :total-rows="total_row" :per-page="search.per_page"/>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="lg" v-model="show_modal.pay_billing" title="Pay Billing" hide-footer @hide="onHidePayBiling">
      <el-steps :active="current_step" finish-status="success" simple="">
        <el-step title="Fill Data" icon="el-icon-warning-outline"></el-step>
        <el-step title="Payment Detail" icon="el-icon-postcard"></el-step>
      </el-steps>
      <template v-if="current_step == 0">
        <b-form>
          <el-descriptions class="mt-2" title="Billing Information" :column="1" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-document"></i>
                Name
              </template>
              {{ selected_billing.name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-money"></i>
                Amount
              </template>
              IDR {{ selected_billing.amount.toLocaleString('id-ID') }}
            </el-descriptions-item>
          </el-descriptions>
          <br/>
          <b-form-group label="Payment Method">
            <el-select v-model="form1.payment_method_id" placeholder="Please choose payment method" class="w-100">
              <el-option v-for="item in sof" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </b-form-group>
          <div class="d-flex flex-row float-right mt-2">
          <el-button :loading="loading.pay_billing" @click="handlerBtnNext" :disabled="!form1.payment_method_id" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
          <el-button @click="show_modal.pay_billing = false" size="small" class="mr-2">Cancel</el-button>
        </div>
        </b-form>
      </template>
      <template v-if="current_step == 1">
        <b-form>
          <el-descriptions class="mt-2" title="Payment Detail" :column="1" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-document"></i>
                Name
              </template>
              {{ selected_billing.name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-money"></i>
                Amount
              </template>
              IDR {{ selected_billing.amount.toLocaleString('id-ID') }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-s-custom"></i>
                Admin Fee
              </template>
              IDR {{ (selected_billing.admin_fee || 0).toLocaleString('id-ID') }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-money"></i>
                Total Payment
              </template>
              IDR {{ (selected_billing.total_transaction || 0).toLocaleString('id-ID') }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>
                Payment Method
              </template>
              {{ selected_billing.payment_metadata.va_list[0].name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-wallet"></i>
                Virtual Account Number
              </template>
              {{ selected_billing.virtual_account_number }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-message"></i>
                Status
              </template>
              <el-tag size="mini" :type="selected_billing.tag_type">{{ selected_billing.status.replace('_', ' ').toUpperCase() }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                Expired Date
              </template>
              {{ moment(selected_billing.expired_at).format('DD MMM YYYY, HH:mm') }}
            </el-descriptions-item>
          </el-descriptions>
          <div class="mt-3 mb-3"><strong>How to pay</strong></div>
          <el-collapse v-model="active_collapse_howtopay" accordion>
            <el-collapse-item v-for="(item, index) in selected_billing.payment_metadata.va_list[0].how_to_pay" :key="index" :title="item.channel" :name="index">
              <ol>
                <li v-for="(step, i) in item.step" :key="i">{{ step }}</li>
              </ol>
            </el-collapse-item>
          </el-collapse>
        </b-form>
        <div class="d-flex flex-row float-right mt-2">
          <el-button @click="show_modal.pay_billing = false" size="small" class="mr-2">Close</el-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import workspaceApi from '../../api/workspaces';
import topupApi from '../../api/topup';
import billingsApi from '../../api/billings';
import popupErrorMessages from '../../library/popup-error-messages';

export default {
  name: 'Billings',
  metaInfo: {
    title: 'Billings',
  },
  data() {
    return {
      moment,
      loading: {
        table: false,
        pay_billing: false,
      },
      active_collapse_howtopay: '',
      show_modal: {
        pay_billing: false,
      },
      sof: [],
      current_step: 0,
      search: {
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
        page: this.$route.query?.page || 1,
        per_page: this.$route.query?.per_page || 10,
        search_keyword: this.$route.query?.search_keyword || '',
        search_by: this.$route.query?.search_by || 'invoice_number',
      },
      rows: [],
      total_row: 0,
      search_by_options: [
        {
          value: 'invoice_number',
          text: 'Invoice Number',
        },
        {
          value: 'reference_number',
          text: 'Ref. Number',
        },
      ],
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      selected_billing: {
        amount: 0,
        admin_fee: 0,
        payment_metadata: {
          va_list: [],
        },
      },
      form1: {
        payment_method_id: '',
      },
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace || {};
    },
    workspaceBalance() {
      return this.$store.state.workspace.workspaceBalance?.toLocaleString('id-ID');
    },
    workspaceFreeQuota() {
      return this.$store.state.workspace.workspaceFreeQuota;
    },
  },
  mounted() {
    this.getList();
    // this.checkWorkspaceBalance();
    topupApi.paymentMethodList().then(({ data }) => {
      this.sof = data.sof;
    });
  },
  methods: {
    async checkWorkspaceBalance() {
      if (this.activeWorkspace._id) {
        const response = await workspaceApi.check_balance({
          id: this.activeWorkspace._id,
        });
        await popupErrorMessages(response);
        this.$store.dispatch('workspace/setWorkspaceBalance', response.data);
      }
    },
    /* eslint-disable radix */
    handlerNextPrev(value) {
      if (value === 'next') {
        this.search.page = parseInt(this.search.page) + 1;
        this.search.previous_page = null;
        if (!this.search.next_page) {
          return;
        }
      } else {
        this.search.page = parseInt(this.search.page) - 1;
        this.search.next_page = null;
        if (!this.search.previous_page) {
          return;
        }
      }
      this.getList();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async getList(action = '') {
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
        search_by: this.search.search_by,
        search_keyword: this.search.search_keyword,
      };

      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      this.loading.table = true;
      if (action === 'download') options.action = 'download';
      await billingsApi.platformBillingHistories({
        id: this.activeWorkspace._id,
        query: options,
      }).then(({ data }) => {
        if (options.action === 'download') {
          this.$message({
            title: this.$t('general.success'),
            type: 'success',
            message: this.$t('general.waiting_download'),
            duration: 30 * 1000,
            showClose: true,
          });
          return;
        }
        this.total_row = data.count;
        this.rows = data.rows.map((v) => {
          v.date_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.topup_amount_str = `Rp. ${v.amount.toLocaleString('id')}`;
          v.tag_type = 'warning';
          // v.topup_receipt_url = topupApi.downloadInvoices({
          //   workspace_id: this.$route.params.workspace_id,
          //   id: v.id,
          // });
          if (v.status === 'paid') {
            v.tag_type = 'success';
          } else if (['expired', 'failed'].includes(v.status)) {
            v.tag_type = 'danger';
          } else if (v.status === 'cancelled') {
            v.tag_type = 'info';
          }
          return v;
        });
      });
      this.loading.table = false;
    },
    handlerSearchBtn() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.search.next_page = null;
      this.search.previous_page = null;
      this.search.page = 1;
      this.getList();
    },
    async downloadReport() {
      this.getList('download');
    },
    handlerBtnPay(item) {
      this.show_modal.pay_billing = true;
      this.selected_billing = item;
      if (item.virtual_account_number) {
        this.current_step += 1;
      }
    },
    onHidePayBiling() {
      this.current_step = 0;
      this.selected_billing = {
        amount: 0,
        admin_fee: 0,
        payment_metadata: {
          va_list: [],
        },
      };
      this.form1 = {
        payment_method_id: '',
      };
    },
    async handlerBtnNext() {
      this.loading.pay_billing = true;
      const { data, error } = await billingsApi.platformBillingGenerateVa({
        workspace_id: this.activeWorkspace._id,
        invoice_id: this.selected_billing.id,
        payment_method_id: this.form1.payment_method_id,
      });
      if (!error) {
        this.current_step += 1;
        this.selected_billing = data;
        this.selected_billing.tag_type = 'warning';
      }
      this.loading.pay_billing = false;
    },
    async handlerDownloadInvoice(item) {
      const { data } = await billingsApi.downloadInvoicePlatform({
        id: item.id,
        workspace_id: this.activeWorkspace._id,
      });
      window.open(data.url);
    },
  },
};
</script>
