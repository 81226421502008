import api from '../library/axios';
// import config from '../config';

export default {
  platformBillingHistories({ id, query = {} }) {
    return api({
      url: `/api/platform-billings/${id}`,
      params: query,
    });
  },
  platformBillingGenerateVa({ workspace_id, payment_method_id, invoice_id }) {
    return api({
      url: `/api/platform-billings/${workspace_id}/pay`,
      method: 'post',
      data: {
        payment_method_id, invoice_id,
      },
    });
  },
  downloadInvoicePlatform({ id, workspace_id }) {
    return api({
      url: `/api/platform-billings/${workspace_id}/${id}/invoice`,
    });
  },
};
